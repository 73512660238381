import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import "../styles/productos.css";
import netflixImg from "../imgs/logoN.jpg";
import disneyImg from "../imgs/disney.jpg";
import primeImg from "../imgs/primevideo.jpg";
import maxImg from "../imgs/max.jpg";
import plexImg from "../imgs/plex.jpg";
import paramountImg from "../imgs/logoParamount.jpg";
import iptvImg from "../imgs/logoIPTV.jpg";
import crunchyrollImg from "../imgs/logoCrunchyroll.jpg";
import canvaImg from "../imgs/logoCanva.jpg";
import vixImg from "../imgs/logoVix.jpg";
import youtubeImg from "../imgs/logoYT.jpg";
import spotifyImg from "../imgs/logoSpotify.jpg";
import { AuthContext } from "../App";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const URL = process.env.REACT_APP_URL_BACKEND;

const Productos = () => {
  const [productoSeleccionado, setProductoSeleccionado] = useState("");
  const [productos, setProductos] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("Netflix");
  const [busqueda, setBusqueda] = useState("");
  const [cuentas, setCuentas] = useState([]);
  const [mostrarCategorias, setMostrarCategorias] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    contrasena: "",
    perfil: "",
  });

  const [subirFormulario, setSubirFormulario] = useState(false);
  const [productoActual, setProductoActual] = useState(null);
  const { auth, setAuth } = useContext(AuthContext);

  const [esAdmin, setEsAdmin] = useState(false);

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await fetch(
          `${URL}/productos?categoria=${categoriaSeleccionada}&buscar=${busqueda}`,
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          const uniqueProducts = Array.from(
            new Map(
              data.map((producto) => [producto.id_producto, producto])
            ).values()
          );
          setProductos(uniqueProducts);
        } else {
          setProductos([]);
        }
      } catch (error) {
        setProductos([]);
      }
    };

    fetchProductos();
  }, [categoriaSeleccionada, busqueda]);

  useEffect(() => {
    if (productoActual) {
      setFormData({
        email: productoActual.email || "",
        contrasena: productoActual.contrasena || "",
        perfil: productoActual.perfil || "",
      });
      setCategoriaSeleccionada(productoActual.nombre_categoria || "");
      setProductoSeleccionado(productoActual.id_producto || "");
    }
  }, [productoActual]);

  useEffect(() => {
    if (auth) {
      try {
        const decodedToken = jwtDecode(auth);
        setEsAdmin(decodedToken.es_admin || false);
      } catch (error) {
        setAuth(null);
      }
    }
  }, [auth, setAuth]);

  useEffect(() => {
    const fetchCuentas = async () => {
      if (categoriaSeleccionada && productoSeleccionado) {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(
            `${URL}/cuentas?categoria=${encodeURIComponent(
              categoriaSeleccionada
            )}&producto=${encodeURIComponent(productoSeleccionado)}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          if (Array.isArray(data)) {
            const groupedData = data.reduce((acc, item) => {
              const {
                id_cuenta,
                correo,
                estado_cuenta,
                id_perfil,
                nombre_perfil,
                estado_perfil,
              } = item;
              if (!acc[id_cuenta]) {
                acc[id_cuenta] = { correo, estado_cuenta, perfiles: [] };
              }
              if (id_perfil) {
                acc[id_cuenta].perfiles.push({
                  id_perfil,
                  nombre_perfil,
                  estado_perfil,
                });
              }
              return acc;
            }, {});
            setCuentas(Object.values(groupedData));
          } else {
            setCuentas([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchCuentas();
  }, [categoriaSeleccionada, productoSeleccionado]);

  const handleCategoriaChange = (e) => {
    setCategoriaSeleccionada(e.target.value);
    setProductoSeleccionado("");
  };

  const toggleCategorias = () => {
    setMostrarCategorias(!mostrarCategorias);
  };

  const handleProductoChange = (e) => {
    setProductoSeleccionado(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const handleInputChangeP = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${URL}/subir-cuenta`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_producto: productoSeleccionado,
          correo: formData.email,
          contrasena: formData.contrasena,
          perfil: formData.perfil,
        }),
      });

      if (response.ok) {
        Swal.fire({
          title: "Éxito",
          text: "La cuenta se subió exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
          customClass: {
            popup: "custom-swal-popup",
          },
        });
        setFormData({
          email: "",
          contrasena: "",
          perfil: "",
        });
        setCategoriaSeleccionada("");
        setProductoSeleccionado("");
      } else {
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al subir la cuenta",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            popup: "custom-swal-popup",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Hubo un error al intentar subir la cuenta",
        icon: "error",
        confirmButtonText: "Aceptar",
        customClass: {
          popup: "custom-swal-popup",
        },
      });
    }
  };

  const handleEliminarProducto = (producto) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "custom-swal-popup",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `${URL}/api/productos/${producto.id_producto}`,
            {
              method: "DELETE",
            }
          );

          if (response.ok) {
            Swal.fire(
              "¡Eliminado!",
              "El producto ha sido eliminado.",
              "success"
            );

            setProductos((prevProductos) =>
              prevProductos.filter(
                (p) => p.id_producto !== producto.id_producto
              )
            );
          } else {
            Swal.fire(
              "Error",
              "Hubo un problema al eliminar el producto.",
              "error"
            );
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "Hubo un error al intentar eliminar el producto.",
            "error"
          );
        }
      }
    });
  };

  const navigate = useNavigate();

  const handleClickR = () => {
    navigate("/modificar-cuentas");
  };

const handleComprarProducto = async (producto) => {
  if (producto.disponibilidad <= 0) {
    Swal.fire({
      title: "Sin Stock",
      text: `Lo sentimos, ${producto.nombre_producto} no está disponible en este momento.`,
      icon: "warning",
      confirmButtonText: "Aceptar",
      customClass: {
        popup: "custom-swal-popup",
      },
    });
    return;
  }

  const confirmacion = await Swal.fire({
    title: "Compra",
    html: `
    <p>¿Quieres comprar ${producto.nombre_producto}?</p>
    <p style="color: #666; font-size: 0.9em;">Se recomienda tomar un capture de pantalla de los datos recibidos por la compra.</p>
  `,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Comprar",
    cancelButtonText: "Cancelar",
  });

  if (confirmacion.isConfirmed) {
    try {
      const response = await fetch(
        `${URL}/comprar-producto/${producto.id_producto}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
          body: JSON.stringify({}),
        }
      );

      const data = await response.json();

      if (response.ok) {
        let message = `
          <p>Has comprado ${producto.nombre_producto}.</p>
          <p>Aquí están los detalles de tu cuenta:</p>
          <p><strong>Correo:</strong> ${data.cuenta.correo}</p>
          <p><strong>Contraseña:</strong> ${data.cuenta.contrasena}</p>`;

        if (data.cuenta.perfil) {
          message += `
            <p><strong>Perfil:</strong> ${data.cuenta.perfil}</p>
          `;
        }
        const fechaCompra = new Date();
        const fechaCorte = new Date(fechaCompra);
        fechaCorte.setDate(fechaCorte.getDate() + 30); 
        const fechaCorteFormatted = fechaCorte.toLocaleDateString(); 

        message += `
          <p><strong>Fecha de Corte:</strong> ${fechaCorteFormatted}</p>
        `;

        const resultado = await Swal.fire({
          title: "Comprado",
          html: message,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Copiar Detalles",
          customClass: {
            popup: "custom-swal-popup",
          },
        });

        if (resultado.isDismissed) {
          const copyText = `
            ${producto.nombre_producto}
            Correo: ${data.cuenta.correo}
            Contraseña: ${data.cuenta.contrasena}
            ${data.cuenta.perfil ? `Perfil: ${data.cuenta.perfil}` : ""}
            
            Reglas:
            1. Una pantalla para un dispositivo.
            2. No compartir tus detalles de cuenta con terceros.
            3. Utilizar el producto de acuerdo a las políticas de uso.
            4. Contactar con soporte si encuentras algún problema.
            
            CUALQUIER INCUMPLIMIENTO CON LAS REGLAS DEL SERVICIO LLEVARÁ A SU SUSPENSIÓN SIN DERECHO A REEMBOLSO`;

          navigator.clipboard
            .writeText(copyText)
            .then(() => {
              Swal.fire(
                "Copiado",
                "Los detalles del producto se han copiado al portapapeles.",
                "success"
              );
            })
            .catch(() => {
              Swal.fire(
                "Error",
                "No se pudo copiar los detalles del producto.",
                "error"
              );
            });
        }

        setProductos((prevProductos) =>
          prevProductos.map((p) =>
            p.id_producto === producto.id_producto
              ? { ...p, disponibilidad: p.disponibilidad - 1 }
              : p
          )
        );
      } else {
        Swal.fire("Error", data.error || "Error en la compra", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un problema al realizar la compra.", "error");
    }
  }
};


  const formatearPrecio = (precio) => {
    return parseFloat(precio).toLocaleString("es-ES", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const categorias = [
    "Netflix",
    "Disney",
    "Max",
    "Prime Video",
    "Plex",
    "Paramount",
    "IPTV",
    "Crunchyroll",
    "Canva Pro",
    "Vix",
    "Youtube Premium",
    "Spotify Premium",
  ];

  const imagenesCategorias = {
    1: netflixImg,
    2: primeImg,
    3: disneyImg,
    4: maxImg,
    5: plexImg,
    6: paramountImg,
    7: iptvImg,
    8: crunchyrollImg,
    9: canvaImg,
    10: vixImg,
    11: youtubeImg,
    12: spotifyImg,
  };

  return (
    <div className="productos-container">
      <div className="header">
        <div className="search-wrapper">
          <button
            className={`toggle-categorias ${
              mostrarCategorias ? "expanded" : ""
            }`}
            onClick={toggleCategorias}
          ></button>

          <input
            type="text"
            placeholder="Buscar producto..."
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
            className="buscador-input"
          />
          <button className="search-button">
            <i className="bi bi-search"></i>
          </button>
        </div>
        <nav className="productos-navbar">
          <ul>
            <li>
              <button
                onClick={() => {
                  setCategoriaSeleccionada("");
                  setSubirFormulario(false);
                }}
              >
                Ver Productos
              </button>
            </li>
            {esAdmin && (
              <>
                <li>
                  <button
                    onClick={() => {
                      setSubirFormulario(true);
                      setCategoriaSeleccionada("");
                      setProductoSeleccionado("");
                      setProductoActual(null);
                      setFormData({
                        email: "",
                        contrasena: "",
                        perfil: "",
                      });
                    }}
                  >
                    Subir Producto
                  </button>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      <div className="productos-panel">
        <div
          className={`navbar-categorias ${
            mostrarCategorias ? "visible" : "hidden"
          }`}
        >
          <ul>
            {categorias.map((categoria) => (
              <li
                key={categoria}
                className={categoriaSeleccionada === categoria ? "active" : ""}
                onClick={() =>
                  handleCategoriaChange({ target: { value: categoria } })
                }
              >
                {categoria}
              </li>
            ))}
          </ul>
        </div>
        <div className="productos-content">
          {subirFormulario ? (
            <div className="formulario-containerS">
              <h2 className="h2FormS">Subir Producto</h2>
              <form onSubmit={handleFormSubmit} className="formularioS">
                <select
                  value={categoriaSeleccionada}
                  onChange={handleCategoriaChange}
                >
                  <option value="">Selecciona una categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria} value={categoria}>
                      {categoria}
                    </option>
                  ))}
                </select>

                <select
                  value={productoSeleccionado}
                  onChange={handleProductoChange}
                  disabled={!productos.length}
                >
                  <option value="">Selecciona un producto</option>
                  {productos.map((producto) => (
                    <option
                      key={producto.id_producto}
                      value={producto.id_producto}
                    >
                      {producto.nombre_producto}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  name="email"
                  placeholder="Correo"
                  value={formData.email}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="contrasena"
                  placeholder="Contraseña"
                  value={formData.contrasena}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="perfil"
                  placeholder="Perfil"
                  value={formData.perfil}
                  onChange={handleInputChangeP}
                  autoComplete="off"
                />
                <button type="submit">{"Subir"}</button>
              </form>
            </div>
          ) : (
            <div className="productos-list">
              {productos.map((producto) => (
                <div className="producto-card" key={producto.id_producto}>
                  <div className="producto-info">
                    <p className="nombreProducto">{producto.nombre_producto}</p>
                    {producto.nombre_categoria === "Netflix" && (
                      <img
                        src={imagenesCategorias[1]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Prime Video" && (
                      <img
                        src={imagenesCategorias[2]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Disney" && (
                      <img
                        src={imagenesCategorias[3]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Max" && (
                      <img
                        src={imagenesCategorias[4]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Plex" && (
                      <img
                        src={imagenesCategorias[5]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Paramount" && (
                      <img
                        src={imagenesCategorias[6]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "IPTV" && (
                      <img
                        src={imagenesCategorias[7]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Crunchyroll" && (
                      <img
                        src={imagenesCategorias[8]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Canva Pro" && (
                      <img
                        src={imagenesCategorias[9]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Vix" && (
                      <img
                        src={imagenesCategorias[10]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Youtube Premium" && (
                      <img
                        src={imagenesCategorias[11]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {producto.nombre_categoria === "Spotify Premium" && (
                      <img
                        src={imagenesCategorias[12]}
                        alt={producto.nombre_categoria}
                        className="classLogos producto-imagen"
                      />
                    )}
                    {![
                      "Netflix",
                      "Prime Video",
                      "Disney",
                      "Max",
                      "Plex",
                      "Paramount",
                      "IPTV",
                      "Crunchyroll",
                      "Canva Pro",
                      "Vix",
                      "Youtube Premium",
                      "Spotify Premium",
                    ].includes(producto.nombre_categoria) && (
                      <p>Imagen no disponible para esta categoría</p>
                    )}
                    <p className="dispinibilidad">
                      Disponibles: {producto.disponibilidad}
                    </p>
                    <p className="precioProducto">
                      {formatearPrecio(producto.precio)}
                    </p>
                    {!esAdmin && (
                      <>
                        <button
                          className="comprar-button"
                          onClick={() => handleComprarProducto(producto)}
                        >
                          Comprar
                        </button>
                      </>
                    )}
                    {esAdmin && (
                      <>
                        <button
                          className="modificar-button"
                          onClick={handleClickR}
                        >
                          Modificar
                        </button>
                        <button
                          className="eliminar-button"
                          onClick={() => handleEliminarProducto(producto)}
                        >
                          Eliminar
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Productos;
