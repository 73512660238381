import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../App";
import Swal from "sweetalert2";
import "../styles/login.css";
import logo from "../imgs/logoNav.jpg";

const URL = process.env.REACT_APP_URL_BACKEND;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("verification") === "success") {
      Swal.fire({
        icon: "success",
        title: "Correo verificado",
        text: "Tu correo ha sido verificado exitosamente. Ahora puedes iniciar sesión.",
        confirmButtonText: "Aceptar",
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const checkTokenExpiration = async () => {
      const token = localStorage.getItem("token");
      
      if (token) {
        const payload = JSON.parse(atob(token.split('.')[1])); 
        const exp = payload.exp * 1000;
        
        if (Date.now() >= exp) {
          localStorage.removeItem("token");
          setAuth(null); 
          navigate("/login"); 
        }
      }
    };
  
    checkTokenExpiration();
  }, [setAuth, navigate]);

  
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor, completa todos los campos.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Reintentar",
      });
      return;
    }
    try {
      const response = await fetch(`${URL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (!response.ok) {
        if (result.message === "Email not verified") {
          Swal.fire({
            icon: "error",
            title: "Correo no verificado",
            text: "Tu correo electrónico no ha sido verificado. Por favor, revisa tu bandeja de entrada para verificarlo.",
            footer:
              '<a href="/resend-verification" style="color: #FF0000; text-decoration: none;">¿No recibiste el correo de verificación?</a>',
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Reintentar",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result.message || "Credenciales inválidas",
            footer:
              '<a href="/resetPass" style="color: #FF0000; text-decoration: none;">¿Olvidaste tu contraseña?</a>',
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Reintentar",
          });
        }
        return;
      }

      const { token } = result;
      localStorage.setItem("token", token);
      setAuth(token);
      navigate("/");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error al iniciar sesión. Intenta nuevamente.",
        footer:
          '<a href="/resetPass" style="color: #FF0000; text-decoration: none;">¿Olvidaste tu contraseña?</a>',
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Reintentar",
      });
    }
  };

  return (
    <div className="login-container">
      <div className="divImg">
        <img src={logo} alt="Logo" className="navbar-logo-img" />
      </div>
      <h2>Iniciar sesión en Instashop</h2>
      <hr className="linea" />
      <form onSubmit={handleLogin} className="login-form">
        <div className="form-group">
          <label htmlFor="email">Correo electrónico</label>
          <input
            type="email"
            id="email"
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="off"
          />
        </div>
        <div className="form-group remember-me">
          <input type="checkbox" id="rememberMe" />
          <label htmlFor="rememberMe">Recuérdame</label>
        </div>
        <button type="submit" className="btn-submit">
          Iniciar Sesión
        </button>
      </form>
      <div className="login-options">
        <a href="/resetPass" className="forgot-password">
          ¿Olvidaste tu contraseña?
        </a>
        <p className="pColor">
          ¿No tienes una cuenta?{" "}
          <a href="/register" className="sign-up">
            Regístrate en Instashop
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
